import React from 'react';
import Heading from '../../Heading/Heading';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';

export default function NotFound() {
    return (
        <div>
            <Heading>Page not found!</Heading>
            <div className="container not-found">
                <FontAwesomeIcon icon={faExclamationTriangle}/>
                <Link to="/">Go to Home Page</Link>
            </div>
        </div>
    )
}
