import React, { useState } from 'react';
import Logo from './Logo';
import Backdrop from '../Components/Backdrop/Backdrop';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser, faSignal, faChartBar, faUserGraduate, faAddressCard, faFileAlt } from '@fortawesome/free-solid-svg-icons';
import { NavLink } from 'react-router-dom';

export default function MainNav() { 
    const [isBackdropOn, setBackdrop] = useState(false);
    
    function openCloseNav() {
        setBackdrop(!isBackdropOn);
    }

    function downloadResume() {
        setBackdrop(!isBackdropOn);
        const url = '/asset/amol_patil.pdf'
        window.open(url,"",500,300)
    }
    
    return (
        <>
        <Backdrop show={isBackdropOn} click={openCloseNav}/>
        <nav>
                <header>
                    <span className="hamburger" aria-label="Toggle visibility of the mobile navbar" onClick={openCloseNav} aria-expanded={isBackdropOn}>&#9776;</span>
                    <Logo />
                </header>
                <div id="mySidenav" className={`sidenav ${isBackdropOn ? "open-nav" : '' }`}>
                    <button className="closebtn" onClick={openCloseNav}>&times;</button>
                    <ul>
                        <li>
                            <NavLink activeClassName="active" to="/about" onClick={openCloseNav} aria-label="Navigate to learn more about Amol">
                            <FontAwesomeIcon icon={faUser} aria-hidden="true"/> About</NavLink>
                        </li>
                        <li>
                            <NavLink activeClassName="active" to="/experience" onClick={openCloseNav} aria-label="Navigate to learn more about Amol's experience">
                            <FontAwesomeIcon icon={faSignal} aria-hidden="true"/> Experience</NavLink>
                        </li>
                        <li>
                            <NavLink activeClassName="active" to="/skills" onClick={openCloseNav} aria-label="Navigate to learn more about Amol's skills">
                            <FontAwesomeIcon icon={faChartBar} aria-hidden="true"/> Skills</NavLink>
                        </li>
                        <li>
                            <NavLink activeClassName="active" to="/education" onClick={openCloseNav} aria-label="Navigate to learn more about Amol's education">
                            <FontAwesomeIcon icon={faUserGraduate} aria-hidden="true"/> Education</NavLink>
                        </li>
                        <li>
                            <NavLink activeClassName="active" to="/contact" onClick={openCloseNav} aria-label="Navigate to learn more about Amol's contact information">
                            <FontAwesomeIcon icon={faAddressCard} aria-hidden="true"/>Contact</NavLink>
                        </li>
                        <li>
                             <div className="button-wrapper"  onClick={downloadResume}>
                                <FontAwesomeIcon icon={faFileAlt} aria-hidden="true"/>
                                <button className="resume-link" aria-label="Opens Amol's resume">Resume</button>
                            </div>
                        </li>
                    </ul>
                </div>
            </nav>
        </>
    )
}
