import React, {useEffect} from 'react';
import { connect } from 'react-redux';
import * as actionType  from '../../../redux/actionType';
import Setstorage from '../../Setstorage/Setstorage';

function Home(props) {
    
    useEffect(() => {
        if(Setstorage('home')) {
            props.addPageCount('home');
        }
    });

    return (
        <>
            <div className="home-intro">
                <h2>I believe, it is simple to code <span className="underlined-text">but difficult to code simple</span></h2>
            </div>
            
        </>
    )
}
const mapStateToProps = (state) => {
    return {
        home : state.home
    }
}

const mapDispatchToProps = (dispatch) => {
    console.log(dispatch)
    return {
        addPageCount : (page) => dispatch( {type: actionType.ADD_PAGE_COUNT, payload:page })
    }
}
export default connect(mapStateToProps,mapDispatchToProps)(Home)
