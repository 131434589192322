import React from 'react';
import Heading from '../../../Components/Heading/Heading';


function About(props) {
    return (
        <>
            <Heading>About</Heading>
            <div className="page-content">
                <h2 className="secondary-intro">Passionate UI Developer-cum-Architect for an Innovative User Interface. Fuelled by high energy levels and boundless Enthusiasm </h2>
                <p className="description">Currently working as an UI Architect in Cognizant technology solutions, Pune, India. I have about 13 years of experience working in UI technologies which have enriched over the time. I am also fortunate to have worked on a number of projects which shaped my mindset as a programmer. I consider myself as "Jack of all trades, master of few"</p>
                <p className="description">I don’t define myself by work I have done, rather would love to define myself by the work I want to do. My sole purpose is to make the web a beautiful place with every line of code.</p>    
                <p className="description">My proficiency is in the area of responsive web development. Now a days, my latest obsession is for React. I like its core features like declarative, component-based and learn once write anywhere. Have spent a good amount of time exploring react and relative technologies and I am enjoying it. </p>
            </div>    
        </>
    )
}

export default About;
