import React, { Component } from 'react';



export default class ErrorBoundaries extends Component {

    state = {
        hasError: false
    }

    static getDerivedStateFromError(error) {
        return {
            hasError: true
        }
    }

    render() {
        if(this.state.hasError) {
            return <p>Something Went wrong</p>
        }
        return (
            <div>
                {this.props.children}
            </div>
        )
    }
}
