import React from 'react';
import uuid from 'react-uuid';

export default function Card({exp}) {

    if(!exp) {
        throw new Error("experience api failed");
    }

    const baseImgPath = '/asset/images/';
    const concatArray = function(heading ,arr) {
        return (
            <>
                <h4>{heading}</h4>
                <p>{arr.join(", ")}</p>
            </>
        )
    }

    const getLabel = (name) => {
        return`Learn more about ${name}`;
    }

    return (
            <>
             {
                exp.map(exp => {
                    return  <div key={uuid()} className="card-component">
                        <div className="card-heading">
                            <img className={exp.className} src={baseImgPath+exp.img} alt="" aria-hidden="true"/>
                            <a href={exp.siteLink} target="_blank" rel="noopener noreferrer" aria-label={getLabel(exp.companyName)}>{exp.companyName}</a>
                            <h3>{exp.designation}</h3>
                        </div>
                        <div className="card-content">
                            <h4>SUMMARY</h4>
                            <ul>
                                {
                                    exp.duties.map(duty => {
                                        return <li key={uuid()}>{duty}</li>
                                    })
                                }
                            </ul>
                            {
                                exp.techstack.length ? concatArray("Technical Stack", exp.techstack) : null
                            }
                            {
                                exp.domain.length ? concatArray("Domains", exp.domain) : null
                            }
                            {
                                exp.tools.length ? concatArray("Tools", exp.tools) : null
                            }
                           
                        </div>
                        <div className="card-footer">
                            <p>{exp.duration}</p>
                        </div>
                        </div>
                })
             }
            </>
    )
}
