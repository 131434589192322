import React, { useState, useEffect } from 'react';
import Card from '../../Card/Card';
import axios from '../../../axios-profile';
import Heading from '../../../Components/Heading/Heading'
import ErrorBoundaries from '../../ErrorBoundaries/ErrorBoundaries';
import Table from '../../Table/Table';

export default function Experience() {
    
    const [experience, setItems] = useState([]);
    useEffect(() => {
        axios.get('/experience.json')
        .then( response => {
            setItems(response.data.experience);
        })
        .catch(error => {
            setItems(false)
        })
    }, []);

    return (
        <>
            <Heading>Experience </Heading>
            <Table />
            <ErrorBoundaries>
            <Card exp={experience}/>
            </ErrorBoundaries>
        </>
    )
}
