import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserGraduate } from '@fortawesome/free-solid-svg-icons';

export default function Tile() {
    return (
        <div className="container">
            <div className="tile">
                <h3><a href='https://mu.ac.in/' target="_blank" rel="noopener noreferrer" area-label="Learn more about University of Mumbai">University of Mumbai</a></h3>
                <span>Mumbai, India</span>
                <p><FontAwesomeIcon icon={faUserGraduate}/> Bachelor of Engineering in Information Technology</p>
            </div>
            <div className="tile">
                <h3><a href='https://msbte.org.in/' target="_blank" rel="noopener noreferrer" area-label="Learn more about MSBTE">MSBTE</a></h3>
                <span>Mumbai, India</span>
                <p><FontAwesomeIcon icon={faUserGraduate}/> Diploma in Computer Technology</p>
            </div>
        </div>
    )
}
