import React from 'react';
import Heading from '../../Heading/Heading';
import Tile from '../../Tile/Tile';

export default function Education() {
    return (
        <>
            <Heading>Education</Heading>
            <Tile />
        </>
    )
}
