import  * as actionType  from './actionType';

const initialState = {
    home: 0,
    about: 0,
    skills: 0,
    experience: 0,
    education: 0,
    contact: 0,
    resume: 0
}

const rootReducer = (state = initialState, action) => {
    const updatedCount = state[action.payload] + 1;
    switch(action.type) {
        case actionType.ADD_PAGE_COUNT : {
            return {
                ...state,
                [action.payload] : updatedCount
            }
        }
        default :
            return state;
    }
}

export default rootReducer;