import React, { Component } from 'react';
import MainNav from '../Components/MainNav';
import Home from '../Components/Pages/Home/Home';
import About from '../Components/Pages/About/About';
import Experience from '../Components/Pages/Experience/Experience';
import Skills from '../Components/Pages/Skills/Skills';
import Education from '../Components/Pages/Education/Education';
import Contact from '../Components/Pages/Contact/Contact';
import NotFound from '../Components/Pages/NotFound/NotFound';
import { Switch, Route } from 'react-router-dom';


export default class ProfileContainer extends Component {
    render() {
        return (
            <>
                <MainNav />
                <div id="pageArea">
                    <Switch>
                        <Route path="/" exact component={Home} />
                        <Route path="/about" component={About} />
                        <Route path="/experience" component={Experience} />
                        <Route path="/skills" component={Skills} />
                        <Route path="/education" component={Education} />
                        <Route path="/contact" component={Contact} />
                        <Route component={NotFound} />
                    </Switch>
                </div>
            </>
        )
    }
}

