import React from 'react'

export default function Table() {
    return (
        <div className="table-content">
            <h3>Key Roles and Responsibilities</h3>
            <ul>
                <li>Design <span>Review</span> and design <span>recommendations</span></li>
                <li>Requirement <span>Analysis</span>, <span>Estimations </span>for modules and sites/application</li>
                <li>Team <span>Mentoring</span> and <span>Trainings</span></li>
                <li>Adherence to <span>Architectural Standards</span></li>    
                <li><span>Problem Solving</span></li> 
                <li><span>Coding</span>, debugging and <span>Testing</span></li> 
                <li>Participation in Agile <span>Scrum Planning</span> <br/><br/>
                    Optimization of <span>Scrum Velocity</span>
                </li>
                <li>Identification of <span>Risks </span> and <span>Technical Debt </span>
                    <br/><br/>
                    Risk <span>Mitigation</span>, <span>maintainable codebase</span>
                </li>
                <li>Team Leading + <span>People Management </span>
                    <br/><br/>
                    Participation in <span>Appraisal Process</span>
                </li>
                <li>Design and execute <span>Proof of Concepts</span></li>
                <li><span>Communication</span> across teams including Business</li>
                <li><span>Module Based</span> Design</li>
            </ul>
        </div>
    )
}
