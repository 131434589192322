import React from 'react';
import uuid from 'react-uuid';



export default function Techstack({skill}) {
    if(!skill) {
        throw new Error("experience api failed");
    }
    const baseImgPath = '/asset/images/';
    return (
        <div className="items-stack">
            <h3>{skill.techText}</h3>
            <ul>
                {
                    skill.stack.map(item => {
                        return <li key={uuid()}>
                            <img src={baseImgPath+ item.imgUrl} alt="" aria-hidden="true"/>
                            <h4>{item.text}</h4>
                        </li>
                    })
                }
            </ul>
        </div>
    )
}