import React from 'react';
import Heading from '../../Heading/Heading';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons';
import { faLinkedin } from '@fortawesome/free-brands-svg-icons'

export default function Contact() {
    return (
        <>
            <Heading>Contact</Heading>
            <div className='container'>
                <ul className='contact'>
                    <li><a href='mailto:amolvijaypatil@gmail.com' target="_blank" rel="noopener noreferrer" aria-label="Contact Amol on email"><FontAwesomeIcon icon={faEnvelope}/> <span>contact@simplyamol.com </span></a></li>
                    <li><a href='https://www.linkedin.com/in/amol-patil-14900817/' rel="noopener noreferrer" target="_blank" aria-label="Contact Amol on linkedin"><FontAwesomeIcon icon={faLinkedin}/> <span>linkedin</span></a></li>
                </ul>
            </div>
        </>
    )
}
