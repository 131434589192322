export default function(page) {
    console.log(page)
    let visitedPageObj = JSON.parse(sessionStorage.getItem("visited"));
    
    if(!visitedPageObj || visitedPageObj[page] === 0) {
        visitedPageObj = {
            [page] : 1
        }
        sessionStorage.setItem("visited", JSON.stringify(visitedPageObj));
        //props.addPageCount('home');
        return true;
    }
    return false;
}