import React, { useState, useEffect } from 'react';
import uuid from 'react-uuid';
import axios from '../../../axios-profile';
import Heading from '../../Heading/Heading';
import Techstack from '../../Techstack/Techstack';
import ErrorBoundaries from '../../ErrorBoundaries/ErrorBoundaries';


export default function Skills() {
    const [skills, setItems] = useState([]);
    useEffect(() => {
        axios.get('/skills.json')
        .then( response => {
            setItems(response.data.skills);
        })
        .catch(error => {
            setItems([false])
        })
    }, [])


    return (
        <div>
            <Heading>Skills</Heading>
            <ErrorBoundaries>
            <div className='container'>
                {
                    skills.map(skill => {
                        return <Techstack key={uuid()} skill={skill} />
                    })
                }
            </div>
            </ErrorBoundaries>
        </div>
    )
}
